.navbar {
    height: 10vh;
    width: 100%;
    background-color: black;
    display: grid;
    place-items: center;
}

.pwlogo {
    height: 10vh;
    width: auto;
}

.navbarGrid {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 2em;
    align-items: center;
    justify-items: start;
}

.whiteText {
    color: white;
    font-size: 1.5em;
    font-family: 'Gilroy-Bold';
    transition: 0.2s color ease;
    cursor: pointer;
}

.whiteText:hover {
    color: #9667FF;
}

.menuStack {
    color: white;
    font-size: 1.5em;
    font-family: 'Gilroy-Bold';
    transition: 0.2s color ease;
    cursor: pointer;
    width: 3.5em;
    text-align: center;
}

.menuStack:hover {
    color: #9667FF;
}

.maxWidthFlexContainer {
    width: 20%;
    height: 100%;
    margin-left: 50%;
    display: grid;
    place-items: center;
}