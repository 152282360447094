.home {
    height: 100%;
    width: 100%;
    background-color: #121212;
}

.home::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.home::-webkit-scrollbar-thumb {
    background: transparent;
}

.flexHeaderContent {
    display: flex;
    width: 95%;
    justify-content: space-between;
    place-items: center;
    margin-top: 2em;
    margin-left: 2em;
}

.whiteHeader {
    font-family: 'Gilroy-Bold';
    color: white;
    cursor: default;
}

.closeFlexHeaderContent {
    display: flex;
    gap: 1em;
    align-items: center;
}

.closeFlexHeaderContentSchedules{
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1em;
    align-items: center;
    width: 80%;
}


.materialButton {
    background-color: transparent;
    font-family: 'Material Icons';
    font-weight: 700;
    color: #ffffff;
    border: 1px solid #676767;
    font-size: 2em;
    border-radius: 50em;
    aspect-ratio: 1;
    transition: 0.3s all ease;
}

.materialButton:hover {
    box-shadow: 0px 0px 20px 1px #C9C4FF;
}

.purpleButton {
    background-color: #6736D3;
    border: none;
    outline: none;
    font-family: 'Gilroy-Bold';
    color: white;
    max-height: 3em;
    max-width: 10em;
    border-radius: 0.8em;
    min-height: 3em;
    min-width: 10em;
    font-size: 1em;
    transition: 0.3s all ease;
}

.purpleButton:hover {
    color: #6736D3;
    background-color: white;
    cursor: pointer;
}

.purpleButton:focus {
    outline: none;
}

.purpleButton:disabled {
    background-color: #C9C4FF;
    color: white;
    cursor: not-allowed;
}

.videosGridWrapper {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.videosGridWrapper::-webkit-scrollbar {
    width: 0;  
    background: transparent; 
}

.hovideosGridWrapperme::-webkit-scrollbar-thumb {
    background: transparent;
}

.videosGrid {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-items: center;
    flex-wrap: wrap;
    grid-gap: 1em;
    justify-content: center;
    padding-top: 3em;
    padding-bottom: 10em;
}

.searchBarAllContent {
    outline: none;
    height: 3.2em;
    width: 20em;
    border-radius: 1em;
    padding-left: 2em;
    padding-right: 2em;
    background-color: #3E3E3E;
    border: 1px solid #707070;
    color: white;
    font-family: 'Gilroy-Light';
}

.searchBarAllContent:focus {
    outline: none;
}

.customDropDown {
    height: 3.2em;
    width: 8em;
    background-color: transparent;
    color: white;
    font-family: 'Gilroy-Bold';
    border: 1px solid #707070;
    border-radius: 1em;
    padding-left: 0.5em;
    transition: 0.3s all ease;
}

.customDropDown:hover {
    box-shadow: 0px 0px 20px 1px #C9C4FF;
}

.customDropDown:focus {
    outline: none;
}

.customDropDown option {
    background-color: #1e1e1e;
}

.modalView {
    display: grid;
    place-items: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 10;
    backdrop-filter: blur(2px);
    transition: 0.4s all ease;
}

.modal {
    background-color: #121212;
    height: 75vh;
    width: 80vw;
    border: 1px solid #707070;
    border-radius: 1em;
    padding-top: 1em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
    transition: 0.4s all ease;
    overflow: hidden;
}

.invisible {
    display: none;
}

.modalOptionsFlex {
    display: grid;
    width: 100%;
    height: 85%;
    grid-template-columns: 2fr 1fr;
}

.videoPreviewAndThumbnailContainer {
    display: grid;
    place-items: center;
    grid-template-rows: 1fr 1fr;
    height: 90%;
    width: 100%;
    grid-gap: 3em;
    background-color: #121212;
}

.videoPreviewThumbnail {
    width: 100%;
    height: auto;
    border-radius: 0.5em;
    border: 1px solid #ffffff;
}

.videoIframe {
    height: 100%;
    width: 100%;
    border-radius: 0.5em;
    border: 1px solid #ffffff;
}

.modalOptionsGrid {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    place-items: center;
    justify-items: start;
}

.editContentDivs {
    height: 100%;
    width: 100%;
}

.editContentBatch {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.editContentTitle {
    height: 100%;
    width: 100%;
}

.editLabelDiv {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 13%;
}

.whiteStaticHeader {
    font-family: 'Gilroy-Bold';
    color: white;
    font-size: 120%;
}

.whiteStaticText {
    font-family: 'Gilroy-Light';
    color: white;
    font-size: 100%;
}

.editTitleInput {
    width: 80%;
    font-family: 'Gilroy';
    color: white;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #707070;
    font-size: 100%;
}

.editTitleButton {
    font-family: 'Material Icons';
    background-color: transparent;
    border: none;
    color: white;   
    font-size: 1.2em;
    width: fit-content;
}

.spreadFlexHeaderContent {
    display: flex;
    gap: 1em;
    align-items: flex-start;
    width: 100%;
}

.whiteStaticLink {
    font: 'Gilroy-Light';
    color: rgb(137, 137, 255)
}

.thumbnailWrapper {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.editThumbnailText {
    position: absolute;
    color: transparent;
    font-size: 1.5em;
    font-family: 'Gilroy-Bold';
    transition: 0.2s color ease;
    cursor: pointer;
    transition: all 0.4s ease;
}

.thumbnailWrapper:hover .editThumbnailText {
    /* color: #6736D3; */
    color: white;
    text-shadow: 2px 2px 5px black;
    scale: 1.5;
}

.existingScheduleDiv{
    display: flex;
    gap: 1em;
}
