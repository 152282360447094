.videoCard {
    height: 15em;
    width: 18em;
    background-color: black;
    border: 5px solid #1E1E1E;
    border-radius: 1em;
    overflow: hidden;
    color: white;
    font-family: 'Gilroy-Light';
    text-align: center;
    padding-bottom: 1em;
    transition: 0.3s all ease;
}

.videoCard:hover{
    scale: 1.02;
    box-shadow: 0px 0px 20px 1px #C9C4FF;
}

.thumbnail {
    width: 100%;
    height: auto;
}

.textWrap {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.3em;
    height: fit-content;
    max-height: 2.7em;
    line-gap-override: 1em;
    text-align: center;
}

.centerName {
    font-family: 'Gilroy-Bold';
    color: white;
}
