.manageProfiles {
    display: grid;
    height: 100%;
    width: 100%;
    align-items: start;
    grid-template-rows: 1fr 4fr;
}

.profilesGridContainer {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.profilesGridContainer::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.profilesGridContainer::-webkit-scrollbar-thumb {
    background: transparent;
}

.profilesGrid {
    display: grid;
    grid-column: auto;
    height: fit-content;
    width: 100%;
    overflow: visible;
    place-items: center;
    align-items: start;
}

.profileCardsHeader {
    height: 4em;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    place-items: center;
    align-items: center;
    border-bottom: 1px solid #7e7e7e;
}

.profileCard {
    height: 4em;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    place-items: center;
    align-items: center;
    border-bottom: 1px solid #7e7e7e;
    overflow: hidden;
}

.userScopes {
    height: 100%;
    width: 100%;
    background-color: transparent;
    color: white;
    border-radius: 1em;
    padding-left: 0.5em;
    transition: 0.3s all ease;
    border: none;
    text-align: center;
}

.userScopes:focus {
    outline: none;
}

.materialButtonSmall {
    background-color: transparent;
    font-family: 'Material Icons';
    font-weight: 700;
    color: #ffffff;
    border: 1px solid #676767;
    font-size: 1.2em;
    border-radius: 50em;
    aspect-ratio: 1;
    transition: 0.3s all ease;
    padding: 0.5em;
}

.materialButtonSmall:hover {
    box-shadow: 0px 0px 20px 1px #C9C4FF;
}

.smallModal {
    height: 30vh;
    width: 40vw;
    background-color: #121212;
    border: 1px solid #707070;
    border-radius: 2em;
    display: grid;
    place-items: center;
    padding: 2em;
}

.addMemberModal {
    height: 80vh;
    width: fit-content;
    background-color: #121212;
    border: 1px solid #707070;
    border-radius: 2em;
    display: grid;
    place-items: center;
    padding: 2em;
    overflow-y: scroll;
}

.modal {
    overflow: scroll;
}

.modal::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.modal::-webkit-scrollbar-thumb {
    background: transparent;
}

.addMemberModal::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.addMemberModal::-webkit-scrollbar-thumb {
    background: transparent;
}

.dropDownSelectGrid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    place-items: start;
    align-items: center;
}

.selectedItemsGrid {
    height: 10em;
    width: 100%;
    display: grid;
    border-left: 1px solid rgb(126, 126, 126, 0.3);
    place-items: center;
    overflow-y: scroll;
    grid-gap: 1em;
    padding-top: 1em;
}

.selectedItemsGrid::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.selectedItemsGrid::-webkit-scrollbar-thumb {
    background: transparent;
}

.selectedItem {
    height: 2em;
    width: 80%;
    display: flex;
    gap: 1em;
    padding: 5px;
    align-items: center;
    border-radius: 5em;
    background-color: #3E3E3E;
}