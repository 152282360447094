.main {
    height: 100vh;
    width: 100vw;
    background-color: #121212;
    display: grid;
    place-items: start;
    grid-template-rows: 1fr 12fr;
    position: relative;
}

.sidebar {
    height: 90%;
    width: 350px;
    background-color: #1E1E1E;
    position: relative;
    position: absolute;
    display: block;
    transition: 0.4s left ease;
    box-shadow: 10px 5px 20px -10px white;
    z-index: 1;
}

.sidebarGrid {
    display: grid;
    width: 100%;
    height: 80%;
    place-items: center;
    grid-row: repeat(5, 1fr);
    align-content: center;
}

.sidebarGridItem {
    display: flex;
    width: 90%;
    gap: 1.5em;
    cursor: pointer;
    justify-content: flex-start;
    transition: 0.2s color ease;
    align-items: center;
    color: #fff;
}

.sidebarGridItem:hover {
    color: #9667FF !important;
}

.activeSidebarGridItem {
    display: flex;
    width: 90%;
    gap: 1.5em;
    cursor: pointer;
    justify-content: flex-start;
    transition: 0.2s color ease;
    align-items: center;
    color: #9667FF !important;
}

.sidebarLabel {
    font-family: 'Gilroy-Bold';
    font-size: 120%;
}

.sidebarMaterialIcon {
    font-family: 'Material Icons';
}

.body {
    height: 90vh;
    width: 100%;
    overflow: hidden;
}

.bodyGrid {
    display: grid;
    grid-template-columns: 1fr 18fr;
    width: 100%;
    height: 100%;
}

.verticalMenu {
    display: grid;
    background-color: #1E1E1E;
    height: 100%;
    width: 5em;
    color: white;
    font-size: 100%;
}

.menuGrid {
    display: grid;
    place-items: center;
    height: 60%;
    width: 100%;
    align-items: start;
    font-size: 100%;
    padding-top: 5em;
}

.childContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.menuMaterialIcon {
    transition: 0.2s color ease;
    cursor: pointer;
    font-family: 'Material Icons';
}

.menuMaterialIcon:hover {
    color: #9667FF;
}

.menuMaterialIconActive {
    cursor: pointer;
    color: #9667FF;
    font-family: 'Material Icons';
}