.uploadMain {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

.uploadInterface {
    background-color: #1E1E1E;
    height: 90%;
    width: 90%;
    border-radius: 1em;
    box-shadow: 0px 0px 20px 10px rgb(255, 255, 255, 0.2);
    display: grid;
    place-items: center;
    grid-template-columns: 3fr 2fr;
    overflow: hidden;
    overflow-y: scroll;
}

.uploadInterface::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.uploadInterface::-webkit-scrollbar-thumb {
    background: transparent;
}

.uploadDetailsContainer {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    height: 95%;
    width: 100%;
    place-items: center;
    justify-items: start;
    padding-left: 5em;
}

.inputBoxContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
}

.titleInput {
    height: 3em;
    width: 40%;
    outline: none;
    border-radius: 1em;
    padding-left: 2em;
    padding-right: 2em;
    background-color: #3E3E3E;
    border: 1px solid #707070;
    color: white;
    font-family: 'Gilroy-Light';
    font-size: 1em;
}

.titleInput:focus {
    outline: none;
}

.titleInput:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.subTitleInput {
    height: 70%;
    width: 80%;
    outline: none;
    border-radius: 1em;
    padding-left: 2em;
    padding-right: 2em;
    background-color: #3E3E3E;
    border: 1px solid #707070;
    color: white;
    font-family: 'Gilroy-Light';
    font-size: 1em;
}

.subTitleInput:focus {
    outline: none;
}

.lectureDetails {
    color: #fff;
    left: 0;
    top: 0;
    width: 100%;
    display: inline;
}

.dropDownLabelUpload {
    color: white;
    font-family: 'Gilroy-Bold';
}

.selectLabelGrid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 70%;
    align-items: center;
    justify-items: start;
}

.uploadGif {
    width: 50%;
    height: auto;
}

.uploadButton {
    display: grid;
    align-content: center;
    place-items: center;
    border: 1px solid #707070;
    border-radius: 2em;
    width: 80%;
    aspect-ratio: 1;
    transition: 0.32s all ease;
}

.uploadButton:hover {
    scale: 1.02;
    box-shadow: 0px 0px 20px 1px #C9C4FF;
}

.videoPreview {
    display: grid;
    row-gap: 2em;
    align-content: center;
    place-items: center;
    border: 1px solid #707070;
    border-radius: 2em;
    width: 90%;
    aspect-ratio: 1;
}

.buttonGridUploadInterface {
    display: flex;
    gap: 1em
}

.redButton {
    background-color: #fb4f4f;
    border: none;
    outline: none;
    font-family: 'Gilroy-Bold';
    color: white;
    max-height: 3em;
    max-width: 10em;
    border-radius: 0.8em;
    min-height: 3em;
    min-width: 10em;
    font-size: 1em;
    transition: 0.3s all ease;
}

.redButton:hover {
    color: #fb4f4f;
    background-color: white;
    cursor: pointer;
}

.redButton:focus {
    outline: none;
}

.redButton:disabled {
    background-color: #ff9797;
    color: white;
    cursor: not-allowed;
}

.videoPlayer {
    max-height: 12em;
    min-height: 1em;
    height: fit-content;
    width: fit-content;
    max-width: 90%;
    min-width: 10%;
}

/* SERCHABLE DD CSS */

.select-search-container {
    --select-search-background: #fff;
    --select-search-border: #dce0e8;
    --select-search-selected: #1e66f5;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-inverted-text: var(--select-search-background);
    --select-search-highlight: #eff1f5;
    --select-search-font: 'Inter', sans-serif;

    width: 300px;
    position: relative;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
    .select-search-container {
        --select-search-font: 'Inter var', sans-serif;
    }
}

@media (prefers-color-scheme: dark) {
    .select-search-container {
        --select-search-background: #000;
        --select-search-border: #313244;
        --select-search-selected: #89b4fa;
        --select-search-text: #fff;
        --select-search-subtle-text: #a6adc8;
        --select-search-highlight: #1e1e2e;
    }
}

body.is-dark-mode .select-search-container {
    --select-search-background: #000;
    --select-search-border: #313244;
    --select-search-selected: #89b4fa;
    --select-search-text: #fff;
    --select-search-subtle-text: #a6adc8;
    --select-search-highlight: #1e1e2e;
}

body.is-light-mode .select-search-container {
    --select-search-background: #fff;
    --select-search-border: #dce0e8;
    --select-search-selected: #1e66f5;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
    box-sizing: inherit;
}

.select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    height: 3.2em;
    width: 8em;
    padding: 0 40px 0 16px;
    background: transparent;
    border: 1px solid #707070;
    color: var(--select-search-text);
    border-radius: 3px;
    outline: none;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 48px;
    letter-spacing: 0.01rem;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    border-radius: 1em;
}

.select-search-is-multiple .select-search-input {
    margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
    border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search-input[readonly] {
    cursor: pointer;
}

.select-search-is-disabled .select-search-input {
    cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input,
.select-search-container:not(.select-search-is-disabled) .select-search-input:hover {
    border-color: var(--select-search-selected);
}

.select-search-select {
    background: var(--select-search-background);
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border: 1px solid #707070;
    overflow: auto;
    max-height: 360px;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
    position: absolute;
    z-index: 2;
    top: 58px;
    right: 0;
    left: 0;
    border-radius: 3px;
    display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
    display: block;
}

.select-search-has-focus .select-search-select {
    border-color: var(--select-search-selected);
}

.select-search-options {
    list-style: none;
}

.select-search-option,
.select-search-not-found {
    display: block;
    height: 42px;
    width: 100%;
    padding: 0 16px;
    background: var(--select-search-background);
    border: none;
    outline: none;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.01rem;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
    background: var(--select-search-highlight);
}

.select-search-is-selected {
    font-weight: bold;
    color: var(--select-search-selected);
}

.select-search-group-header {
    font-size: 12px;
    text-transform: uppercase;
    background: var(--select-search-border);
    color: var(--select-search-subtle-text);
    letter-spacing: 0.1rem;
    padding: 10px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
    margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
    margin-bottom: 10px;
}

.selectRootClass {
    width: 100%;
    border-radius: 1em;
}

.selectInputClass {
    height: 3.2em;
    background-color: transparent;
    color: white;
    border-radius: 1em;
    font-family: 'Gilroy-Bold';
    border: 1px solid #707070;
    padding-left: 0.5em;
    transition: 0.3s all ease;
}

.selectRootClass:hover {
    box-shadow: 0px 0px 20px 1px #C9C4FF;
}