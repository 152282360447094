.batchWrapper{
    display: grid;
    height: 90%;
    width: 93%;
    background: transparent;
    z-index: 10;
    position: absolute;
    backdrop-filter: blur(3px);
    overflow: hidden;
}

.batchUploadDivWrapper{
    position: absolute;
    top: 30%;
    height: 20vh;
    width: 30vw;
    display: flex;
    justify-self: center;
    border: 1px solid #707070;
    border-radius: 2em;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.batchUploadDiv{
    height: 90%;
    width: 90%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    place-items: center;
}

.uploadContents{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5%;

}

.buttonsDiv{
    height: 100%;
    width: 100%;
    display: flex;
    gap: 10%;
    justify-content: center;
    align-items: center;
}

.batchInputField::-webkit-file-upload-button {
    visibility: hidden;
}

.batchInputField::before {
    font-family: 'Gilroy-Light';
    content: 'Select CSV File';
    display: inline-block;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    color: white;
  }
  .batchInputField:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }