.centersCardsHeader {
    height: 4em;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(3, 1fr) 2fr;
    place-items: center;
    align-items: center;
    border-bottom: 1px solid #7e7e7e;
}

.centerCard {
    height: 4em;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(3, 1fr) 2fr;
    place-items: center;
    align-items: center;
    border-bottom: 1px solid #7e7e7e;
    overflow: hidden;
}

.centersGrid {
    display: grid;
    grid-column: auto;
    height: fit-content;
    width: 100%;
    overflow: visible;
    place-items: center;
    align-items: start;
}